<script>
import Vue from 'vue'
import { action_items,container_status,container_box_status } from "@/definition.js";
import { mapState, mapActions } from "vuex";
import BaseFunction from "../BaseFunction.js";
Vue.use(require('sprintf-js'))
export default {
  data: vm => ({
    url: "",
    getParams: {},
    filterItem: {},
    showDialog: false,
    showAlert: false,
    alertItem: null,
    allowAlert: true,
    action: action_items.view,
    action_items: action_items,
    container_status: container_status,
    container_box_status: container_box_status,
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [20, 50, 100]
    },
    headers: [],
    defaultItem: {},
    orig_dialogItem: {},
    dialogItem: {},
    defaultTimeRange: false,
    defaultTimeField: "update_time",
    region_items: [],
    tcHeaders: [],
    scHeaders: [],
    headersMap: {},
  }),
  components: {
    // CommonAlertDialog
  },
  computed: {
    ...mapState(["token", "user","language","role"]),
    nameField(){
      if (this.$i18n.locale === 'zhHant'){
        return 'name_tc'
      } else {
        return 'name_sc'
      }
    },
    nameFieldFilter(){
      return this.nameField + '__startswith'
    },
    showHeaders () {
      var headers = []
      var headerKeys = this.tcHeaders
      if (this.language==='zhHans') {
        headerKeys = this.scHeaders
      }
      headerKeys.forEach(key => {
        headers.push(this.headersMap[key])
      })
      return headers
    },
    windowHeight(){
      console.log("window.innerHeight",window.innerHeight)
      return window.innerHeight
    },
    isTraditional(){
      return (this.$i18n.locale=='zhHant')?true:false
    },
    isSimplified(){
      return (this.$i18n.locale=='zhHans')?true:false
    },
    isAdmin(){
      return (this.role===0)?true:false
    },
  },
  methods: {
    sprintf: sprintf,
    ...mapActions(["submitData"]),
    createItem(item) {
      this.dialogItem = this._.cloneDeep(this.defaultItem);
      this.action = action_items.create;
      this.showDialog = true;
    },
    viewItem(item) {
      this.dialogItem = this._.cloneDeep(item);
      this.action = action_items.view;
      this.showDialog = true;
    },
    editItem(item) {
      console.log("editItem")
      this.orig_dialogItem = this._.cloneDeep(item);
      this.dialogItem = this._.cloneDeep(item);
      this.action = action_items.edit;
      this.showDialog = true;
    },
    deleteItem (item,key="",value="") {
      if (confirm(this.$i18n.t('delete-confirm',{key:key,value:value}))) {
        this.action = action_items.delete
        this.onSaveEvent(item)
      }
    },
    disableItem (item,key="",value="") {
      if (confirm(this.$i18n.t('disable-confirm',{key:key,value:value}))) {
        this.action = action_items.edit
        var disable_item = {
          id: item.id,
          enable: false
        }
        this.onSaveEvent(disable_item)
      }
    },
    onCloseEvent(item) {
      this.showDialog = false;
      this.dialogItem = this._.cloneDeep(this.defaultItem);
    },
    onCloseAlert(item) {
      this.showAlert = false;
    },
    onSaveEvent(item) {
      this.showDialog = false;
      console.log("on save" + JSON.stringify(item));
      if (this.action == action_items.create) {
        this.postHandler(item);
      } else if (this.action == action_items.edit) {
        this.patchHandler(item);
      } else if (this.action == action_items.delete) {
        this.deleteHandler(item);
      }

      this.action = action_items.view;
      this.dialogItem = this._.cloneDeep(this.defaultItem);
    },
    onSaveFileEvent(item) {
      this.showDialog = false;
      console.log("on save file" + JSON.stringify(item));
      if (this.action == action_items.create) {
        this.postFileHandler(item);
      }
    },
    postHandler(item) {
      this.postApi(item);
    },
    postFileHandler(item) {
      this.postFileApi(item);
    },
    prePostApi(jsonData) {
      // console.log('prePostApi before',jsonData)
      let validate_filed_keys = ['container_number']
      validate_filed_keys.forEach((key)=>{
        if (Object.keys(jsonData).includes(key)) {
          jsonData[key] = jsonData[key].trim()
        }
      })
      // console.log('prePostApi after',jsonData)
      return jsonData;
    },
    postPostApi(action, responseData) {
      this.getApi();
    },
    patchHandler(item) {
      console.log("orig_dialogItem " + JSON.stringify(this.orig_dialogItem));
      if (this._.isEqual(item, this.orig_dialogItem) === false) {
        const diff = this.$common_util.difference(this.orig_dialogItem, item);
        console.log("diff: " + JSON.stringify(diff));
        this.patchApi(item.id, diff);
      }
    },
    prePatchApi(id, jsonData) {
      return {};
    },
    postPatchApi(action, responseData) {
      this.getApi();
    },
    updateField(id, field, value) {
      console.log(field + ": " + value);
      var jsonData = {};
      jsonData[field] = value;
      this.patchApi(id, jsonData);
    },
    deleteHandler(item) {
      this.deleteApi(item.id)
    },
    preDeleteApi(id) {
      return {};
    },
    postDeleteApi(action, responseData) {
      this.getApi();
    },
    getApi(
      sub_url = this.url,
      set_result_func = this.setResult,
      skip_get_params = false,
      getParams = this.getParams,
      filterItem = this.filterItem,
      options = this.options,
    ) {
      this.loading = true;
      const currentObj = this;
      // console.log('this.options',options)
      const { sortBy, sortDesc, page, itemsPerPage } = options;
      const url = process.env.VUE_APP_SERVER_URL + sub_url;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token
        }
      };
      if (skip_get_params == false) {
        config["params"] = {
          page: page,
          page_size: itemsPerPage
        };
        // order
        if (Array.isArray(sortBy) && sortBy.length > 0) {
          // console.log('sortBy',sortBy)
          let array = [];
          let item = "";
          for (let i = 0; i < sortBy.length; i++) {
            let sortStr = sortBy[i].replace(".", "__");
            if (sortDesc[i]) {
              item = sortStr;
            } else {
              item = "-" + sortStr;
            }
            array.push(item);
          }
          config.params["ordering"] = array.join();
        }
        // params
        for (const [key, value] of Object.entries(getParams)) {
          if (!this.$common_util.isEmpty(value)) {
            if (key.endsWith("__in") && !value.includes(",")) {
              config.params[key] = this.$common_util.getRange(value);
            } else {
              config.params[key] = value;
            }
          }
        }
        // fitler
        for (const [key, value] of Object.entries(filterItem)) {
          if (!this.$common_util.isEmpty(value)) {
            if (key.endsWith("__range") && !value.includes(",")) {
              console.log(key + ": " + value);
              config.params[key] = this.$common_util.getRange(value);
            } else {
              config.params[key] = value;
            }
          }
        }
      }

      this.axios
        .get(url, config)
        .then(function(response) {
          // console.log(response.data);
          set_result_func(response.data);
          currentObj.loading = false;
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          if (currentObj.allowAlert) {
            currentObj.alertItem = error.response;
            currentObj.showAlert = true;
          }
          currentObj.loading = false;
        });
    },
    getFileApi(sub_url, file_name = "test", file_suffix = ".xlsx") {
      this.loading = true;
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + sub_url;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        responseType: 'arraybuffer'
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          let blob = new Blob([response.data], {type: "application/octet-stream"});
          let objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectUrl;
          const time  = currentObj.getCurrentTimestamp()
          link.setAttribute('download', file_name + "_" + time + file_suffix);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
          currentObj.selectedItem = null;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          var enc = new TextDecoder("utf-8")
          var arr = new Uint8Array(error.response.data)
          if (currentObj.allowAlert) {
            currentObj.alertItem = JSON.parse(enc.decode(arr));
            currentObj.showAlert = true;
          }
          currentObj.loading = false;
        });
    },
    postApi(jsonData, sub_url = this.url, after_post_func = this.postPostApi) {
      let action = null
      jsonData = this.prePostApi(jsonData);
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + sub_url;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token
        }
      };
      this.axios
        .post(url, jsonData, config)
        .then(function(response) {
          console.log(response.data);
          // console.log(currentObj.rawDataParser(sub_url,jsonData))
          after_post_func(action, response.data);
        })
        .catch(function(error) {
          console.log(error);
          if (typeof(error)=='object') {
            console.log(error.response);
            if (currentObj.allowAlert) {
              currentObj.alertItem = error.response;
              currentObj.showAlert = true;
            }
          } else if (typeof(error)=='string') {
            if (error.includes('Network Error')) {
              currentObj.alertItem = this.$i18n.t('alert.network-error',{'rawData':currentObj.rawDataParser(sub_url,jsonData)})
              currentObj.showAlert = true;
            } else {
              currentObj.alertItem = error;
              currentObj.showAlert = true;
            }
          }
          currentObj.loading = false;
        });
    },
    rawDataParser(url,jsonData){
      console.log(url,jsonData)
      if (url=="/container/container_box/") {
        let text = ""
        text += this.$i18n.t('container.code') + ":" + jsonData.code + ","
        text += this.$i18n.t('container.box_str') + ":" + jsonData.start_box + "-" + jsonData.end_box + ","
        let commoditys = []
        if (jsonData.commoditys) {
          jsonData.commoditys.forEach(data=>{
            commoditys.push(data.title)
          })
        }
        text += this.$i18n.t('commodity.commodity') + ":" + commoditys.join(',') + ","
        if (jsonData.supplier_item) {
          text += this.$i18n.t('supplier.supplier') + ":" + this.getHanText(jsonData.supplier_item,"name") + ","
        }
        if (jsonData.customer_item) {
          text += this.$i18n.t('customer.customer') + ":" + this.getHanText(jsonData.customer_item,"name")
        }
        return text
      }
      return jsonData
    },
    postFileApi(jsonData, sub_url = this.url, file_name = "test",file_suffix = ".xlsx") {
      this.loading = true;
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + sub_url;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token
        },
        responseType: 'arraybuffer'
      };
      const formData = new FormData();
      for (const [key, value] of Object.entries(jsonData)){
        console.log(key,value)
        formData.append(key, value)
      }
      this.axios
        .post(url, formData, config)
        .then(function(response) {
          currentObj.loading = false;
          let blob = new Blob([response.data], {type: "application/octet-stream"});
          let objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectUrl;
          const time  = currentObj.getCurrentTimestamp()
          link.setAttribute('download', file_name + "_" + time + file_suffix);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          var enc = new TextDecoder("utf-8")
          var arr = new Uint8Array(error.response.data)
          // console.log(JSON.stringify(error.response.data))
          if (currentObj.allowAlert) {
            currentObj.alertItem = JSON.parse(enc.decode(arr));
            // currentObj.alertItem = JSON.stringify(error.response.data);
            currentObj.showAlert = true;
          }
          currentObj.loading = false;
        });
    },
    patchApi(id, jsonData, sub_url = this.url) {
      let action = this.prePatchApi(id, jsonData);
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + sub_url + id + "/";
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token
        }
      };
      this.axios
        .patch(url, jsonData, config)
        .then(function(response) {
          console.log(response.data);
          currentObj.postPatchApi(action, response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          if (currentObj.allowAlert) {
            currentObj.alertItem = error.response;
            currentObj.showAlert = true;
          }
          currentObj.loading = false;
        });
    },
    deleteApi(id, sub_url = this.url) {
      let action = this.preDeleteApi(id);
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + sub_url + id + "/";
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token
        }
      };
      this.axios
        .delete(url, config)
        .then(function(response) {
          console.log(response.data);
          currentObj.postDeleteApi(action, response.data)
        })
        .catch(function(error){
          console.log(error);
          console.log(error.response);
          
          console.log('error.response.status',error.response.status)
          if (error.response.status===500) {
            // TODO
            // alert(this.$i18n.t('delete-failure.fk'))
            alert('FK')
          }
          currentObj.loading = false;
        })
    },
    setResult(data) {
      this.totalItems = data.count;
      this.items = data.results;
    },
    setListItems(datas, target_items, text_key1 = "name", text_key2 = "", value_key = "id") {
      datas.forEach((data) => {
        target_items.push({
          text: data[text_key1]?data[text_key1]:data[text_key2],
          value: data[value_key],
        });
      });
    },
    onFilterChange(item) {
      this.filterItem = JSON.parse(JSON.stringify(item));
      this.options.page = 1;
      this.getApi();
    },
    setDefaultTimeRange() {
      let rtn =
        this.$moment()
          .startOf("day")
          .toISOString() +
        "," +
        this.$moment()
          .endOf("day")
          .toISOString();
      return rtn;
    },
    logout() {
      this.submitData({
        user: {
          id: 0,
          name: ""
        },
        token: "",
        role: 0,
        merchant: {
          id: 0,
          name: ""
        }
      });
      this.$vuetify.theme.themes["light"].primary = "#4CAF50";
      this.$vuetify.theme.themes["light"].success = "#4CAF50";
      this.$router.push({
        name: "Login"
      });
    },
    getCurrentTimestamp(){
      let date = new Date();
      const dataValues = [
        date.getFullYear(),
        String(date.getMonth() + 1).padStart(2, '0'),
        String(date.getDate()).padStart(2,'0'),
        "_",
        String(date.getHours()).padStart(2,'0'),
        String(date.getMinutes()).padStart(2,'0'),
        String(date.getSeconds()).padStart(2,'0'),
      ];
      return dataValues.join("")
    },
    getHanText: BaseFunction.getHanText,
    arraySum: BaseFunction.arraySum,
    fixedFloat: BaseFunction.fixedFloat,
  },
  watch: {
    options: {
      handler() {
        // console.log("watch options handler()");
        if (this.defaultTimeRange == true) {
          this.getParams[
            this.defaultTimeField + "__range"
          ] = this.setDefaultTimeRange();
        }
        this.getApi();
      }
    }
  },
  mounted() {
    this.$i18n.locale = this.language
  }
};
</script>
