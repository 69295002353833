const action_items = {
    view: 0,
    create: 1,
    edit: 2,
    delete: 3
};

const role_items = [
    { text: "system_admin", value: 0 },
    { text: "account_admin", value: 1 },
    { text: "operator_admin", value: 2 },
    { text: "operator_user", value: 3 },
    { text: "operator_user_chinese", value: 4 },
    { text: "customer_user", value: 10 },
];

const container_status = {
    enable: 1,
    done: 2,
    lock: 3,
    close: 4,
    invoice: 5,
    disable: 9,
};

const container_status_items = [
    { text: "container_status.enable", value: 1},
    // { text: "done", value: 2},
    { text: "container_status.applying", value: 3},
    { text: "container_status.applyed", value: 4},
    { text: "container_status.invoiced", value: 5},
    { text: "container_status.disable", value: 9},
];

const container_box_status = {
    enable: 1,
    container: 2,
    deliver_fee_checked: 3,
    has_receive_time: 4,
    notified: 5,
    disable: 9,
}

const container_isnull_items = [
    { text: "container.is_null", value: true },
    { text: "container.not_null", value: false },
];

const unit_items = [
    { text: "unit.pieces", value: 1 },
    { text: "unit.weight", value: 2 },
    { text: "unit.length", value: 3 },
];

const invoice_need = {
    no: 0,
    self: 1,
    more: 2,
}

const invoice_items = [
    { text: "customer.no_invoice", value: 0 },
    { text: "customer.self_invoice", value: 1 },
    { text: "customer.more_invoice", value: 2 },
    { text: "customer.no_invoice_appended", value: 3 },
];

const language_items = [
    { text: "language.traditional_chinese", value: 1 },
    { text: "language.simplified_chinese", value: 2 },
];

const check_status = {
    init: 1,
    check: 2,
};

const check_options = {
    uncheck_tw: 1,
    uncheck_cn: 2,
    check: 3,
}

const payment_types = {
    customer_pay: 1,
    cash_on_deliver_self_pick_up: 2,
    cash_on_deliver_delivered: 3,
}

const payment_items = [
    { text: "container-box.customer_pay", value: 1 },
    { text: "container-box.cash_on_deliver_self_pick_up", value: 2 },
    { text: "container-box.cash_on_deliver_delivered", value: 3 },
];

const transport_status_items = [
    // { text: "備貨中", value: 0 },
    { text: "已收貨", value: 1 },
    { text: "已裝櫃", value: 2 },
    { text: "中國海關", value: 3 },
    { text: "台灣海關", value: 4 },
    { text: "可領貨", value: 5 },
]

const log_entry_action_items = [
    { text: "log_entry_action_items.create", value: 0 },
    { text: "log_entry_action_items.update", value: 1 },
    { text: "log_entry_action_items.delete", value: 2 }
  ];

const compare_status_items = [
    { text: "container.uncompare", value: 1 },
    { text: "container.comparing", value: 2 },
    { text: "container.compared", value: 3 },
]

const deliver_fee_unit_items = [
    { text: "unit.pieces", value: 1 },
    { text: "unit.weight", value: 2 },
    { text: "unit.cubic-feet", value: 3 },
    { text: "unit.package", value: 4 },
]

const currency_items = [
    { text: "currency.twd", value: 1 },
    { text: "currency.cny", value: 2 },
]

export {
    action_items,
    role_items,
    container_status,
    container_status_items,
    container_box_status,
    container_isnull_items,
    unit_items,
    invoice_need,
    invoice_items,
    language_items,
    check_status,
    check_options,
    payment_types,
    payment_items,
    transport_status_items,
    log_entry_action_items,
    compare_status_items,
    deliver_fee_unit_items,
    currency_items,
};