export default {
    getHanText(item, key) {
        let tc_key = key + "_tc"
        let sc_key = key + "_sc"
        if (this.$i18n.locale=='zhHant') {
          return item[tc_key]?item[tc_key]:item[sc_key]
        }
        return item[sc_key]?item[sc_key]:item[tc_key]
      },
      arraySum(items, prop){
        return items.reduce( function(a,b){
          return a + b[prop]
        }, 0);
      },
      fixedFloat(val, digits = 2, default_value = '-') {
        if (parseFloat(val) > 0) {
          return parseFloat(val).toFixed(digits)
        }
        return default_value
      },
}